import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

const DIRECTION_LEFT = 'left';
const DIRECTION_RIGHT = 'right';
const TYPES = ['primary', 'primary-ghost', 'neutral-ghost'];

const ButtonArrow = ({
  className,
  direction,
  offsetX = {},
  size = 'size3',
  type = TYPES[0],
  ...rest
}) => (
  <Button
    className={twMerge(
      'absolute top-1/2 z-10 w-10 -translate-y-1/2 rounded-full p-2',
      direction === DIRECTION_LEFT && offsetX.left,
      direction === DIRECTION_RIGHT && offsetX.right,
      className
    )}
    icon={
      <Icon
        name={
          direction === DIRECTION_LEFT ? 'arrow-backwards' : 'arrow-forward'
        }
      />
    }
    size={size}
    type={type}
    {...rest}
  />
);

ButtonArrow.propTypes = {
  type: PropTypes.oneOf(TYPES),
  direction: PropTypes.string.isRequired,
  offsetX: PropTypes.shape(),
  size: PropTypes.string,
};

export default ButtonArrow;
